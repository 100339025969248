<template lang="pug">
#accounts

  h1.page-title Research Library
  .page-subtitle
    p Welcome to the UserEvidence research library.
    p Click on any logo to see real examples of competitive intelligence, product stats, and ROI data collected with our customer evidence platform.
    p UserEvidence customers can give their GTM team the verified proof points they need to speed up the sales cycle, win over competitors, and break into new markets.

  section.uerl_filters
    .left_side_filters
      BFormInput(v-model='q' placeholder='Filter by Company Name' debounce='500')
    .right_side_filters
      BFormSelect(:options='sort_options' v-model='sort' @change='setSort')
      BFormSelect(:options='the_size_group_filters' v-model='size_group')
      BFormSelect(:options='the_sector_filters' v-model='sector_id')
  section.customer_cards(v-if='initialized')
    a.customer(v-for='account in clean_accounts' :href='`/user-research-library/${account.slug}`' :key='`account-${account.id}`')
      .header
        h4 {{account.name}}
        span.badge(v-if='is_new(account)') New
      .logo
        figure(v-html='account.svg_logo_mark')
      .footer
        .stat(v-if='account.size_group')
          UserIcon
          span {{size_map[account.size_group]}}
        .stat(v-if='account.sector_name')
          IndustryIcon
          span {{account.sector_name}}
    section.no_results(v-if='clean_accounts.length == 0')
      h1
        | No Results Found
        |
        span(v-if='q.length > 0') for '{{q}}'
</template>

<script>
import axios from 'axios'
import UserIcon from '@/components/graphics/UserIcon.vue'
import IndustryIcon from '@/components/graphics/AccountIcon.vue'
import filters from '@mixins/filters.js'
import url_manager from '@mixins/url_manager'
import { useAccountStore } from '@app/stores/AccountStore'
import ahoy from 'ahoy.js'

export default {
  name: 'Accounts',
  components: { UserIcon, IndustryIcon },
  mixins: [filters, url_manager],
  data() {
    return {
      filters: [],
      accounts: [],
      sort_options: {
        'iname|asc': 'Company Name: A - Z',
        'iname|desc': 'Company Name: Z - A',
        'created_at|desc': 'Most Recently Added',
      },
      sort_by: this.$route.query.sort_by || 'iname',
      sort_dir: this.$route.query.sort_dir || 'asc',
      sort: 'iname|asc',
      size_map: {
        small_business: '1 - 50',
        medium_enterprise: '50 - 1000',
        large_enterprise: '> 1000',
        fortune_500: '> 5000',
      },
    }
  },
  head: {
    title: 'UserEvidence | Research Library',
    meta: [
      { name: 'twitter:title', content: 'UserEvidence Research Library' },
      {
        name: 'twitter:description',
        content: 'See how UserEvidence customers are utilizing content assets for their business.',
      },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:image', content: 'https://www.userevidence.com/img/external/Library.png' },
      { name: 'twitter:image:alt', content: 'UserEvidence Research Library Hero Image' },
      { property: 'og:title', content: 'UserEvidence Research Library' },
      {
        property: 'og:description',
        content: 'See how UserEvidence customers are utilizing content assets for their business.',
      },
      { property: 'og:image', content: 'https://www.userevidence.com/img/external/Library.png' },
      { property: 'og:url', content: 'https://www.userevidence.com/user-research-library' },
      { property: 'og:site_name', content: 'UserEvidence, Inc.' },
    ],
  },
  computed: {
    clean_accounts() {
      return this.accounts.filter((account) => ![1, 2, 3].includes(account.id))
    },
  },
  watch: {
    q() {
      this.fetchAccounts()
    },
    sector_id() {
      this.fetchAccounts()
    },
    size_group() {
      this.fetchAccounts()
    },
  },
  mounted() {
    // the dropdown combines sort_by and sort_dir into a single
    // value, so if these values are passed in via the url
    // we have to use them to reconstruct the value of `sort`
    if (this.$route.query.sort_by && this.$route.query.sort_dir)
      this.sort = `${this.$route.query.sort_by}|${this.$route.query.sort_dir}`
    else this.sort = 'iname|asc'
    this.fetchAccounts()

    // we are doing this here to get the app json for koala tracking.
    // note this request will fail if the user is not logged in via the app.
    // be aware that we'll be seeing 401's in the logs because of this
    useAccountStore().getApp()

    ahoy.trackView()
  },
  methods: {
    is_new(account) {
      return Math.abs((new Date() - new Date(account.created_at)) / 60 / 60 / 24 / 1000) < 31
    },
    fetchAccounts() {
      Promise.all([
        axios.get(`/api/accounts.json`, { params: this.the_query }),
        axios.get(`/api/accounts/account_filters.json`, { params: this.the_query }),
      ]).then((r) => {
        this.accounts = r[0].data.accounts
        this.size_group_filters = r[1].data.size_group_filters
        this.sector_filters = r[1].data.sector_filters
        this.initialized = true
      })
    },
    // the dropdown combines sort_by and sort_dir into a single
    // value stored in `sort`.  we have to unwind this into
    // the individual values when the drop down changes
    setSort() {
      this.sort_by = this.sort.split('|')[0]
      this.sort_dir = this.sort.split('|')[1]
      this.fetchAccounts()
    },
  },
}
</script>

<style lang="sass" scoped>

.page-title, .page-subtitle

  // Here we match the whitespace of the content below
  // This margin should be managed with a centralized class!
  margin-left: 64px
  @media (max-width: 960px)
    margin-left: 16px

.page-title
  font-family: "Viga", sans-serif
  font-size: 2.5rem
  font-weight: 400
  text-transform: uppercase
.page-subtitle
  font-size: 0.7rem
  font-weight: 200
</style>
